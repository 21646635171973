
import { Component, Vue } from 'vue-property-decorator';
import { URI_AUTHORIZE_URL } from '@/api/eob/endpoints';
import { AuthorizeUrlParams } from './types';
import { USER_NOTIFY } from '@/store/list';
import { namespace } from 'vuex-class';

const AppStore = namespace('AppStore');

@Component({
  name: 'Login'
})
export default class Login extends Vue {
  created (): void {
    this.fetchLoginUrl();
  }

  @AppStore.Action(USER_NOTIFY) notify: any;

  async fetchLoginUrl (): Promise<void> {
    try {
      const redirectOrigin = window.location.origin;
      const authorizeUrlParams: AuthorizeUrlParams = { redirectUri: `${redirectOrigin}/autoryzacja` };
      const authSystemUrl = (await this.$api.post<any>(URI_AUTHORIZE_URL, authorizeUrlParams)).data.url;
      this.handleSuccessResponse(authSystemUrl);
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  handleSuccessResponse (authSystemUrl: string): void {
    window.location.href = authSystemUrl;
  }

  handleErrorResponse (e: XMLHttpRequest): void {
    this.notify({ msg: e, type: 'error', permanent: true });
  }
}
